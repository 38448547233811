<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      open-delay="5000"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="text-h5">Partes de ingreso</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row class="ml-3 my-5">
            <h2>{{ productName }}</h2>
          </v-row>
          <v-row justify="center">
            <v-data-table
              :headers="headers"
              :items="providers"
              item-key="id"
              class="elevation-1"
              :search="search"
              sort-by="created_at"
              :sort-desc="true"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Buscar proveedores"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template #item.price_per_product="{ item }">{{
                item.ammount_paid / item.quantity
              }}</template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    providers: [],
    productName: '',
    dialog: false,
    search: ''
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Fecha de cracion',
          value: 'created_at',
          formatter: this.formatDate
        },
        {
          text: 'Proveedor',
          align: 'start',
          sortable: true,
          value: 'supplier'
        },
        {
          text: 'Desc. Proveedor',
          value: 'supplier_desc',
          filtereable: true
        },
        { text: 'Cantidad', value: 'quantity' },
        { text: 'Precio S/', value: 'ammount_paid', sortable: true },
        {
          text: 'Parte de entrada',
          value: 'input_nro'
        },
        {
          text: 'Precio por producto S/',
          value: 'price_per_product'
        },
        {
          text: 'Lote',
          value: 'lote'
        },
        {
          text: 'Expiración de producto',
          value: 'product_expire'
        },
        {
          text: 'Nro. de factura',
          value: 'bill_nro'
        },
        {
          text: 'Fecha de Emisión',
          value: 'bill_emission'
        }
      ]
    }
  },
  created: function() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SET_REPORTS') {
        console.log(state)
        this.productName = state.products.productName
        this.providers = state.products.reports
        this.dialog = true
      }
    })
  },
  methods: {
    formatDate: function(val) {
      return new Date(val).toISOString().slice(0, 10)
    }
  }
}
</script>

<template>
  <div class="home">
    <v-dialog v-model="add_dialog" max-width="80%">
      <v-card>
        <v-card-title>
          <span class="headline" v-if="!!selected_item.product"
            >Añadir Parte de Ingreso {{ selected_item.product.code }}</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form autocomplete="off" ref="parteForm" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="parte.supplier"
                    label="Proveedor"
                    counter
                    maxlength="50"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="parte.supplier_desc"
                    label="Descripción del proveedor para producto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="parte.lote"
                    label="Lote"
                    counter
                    maxlength="8"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    v-model="parte.quantity"
                    label="Cantidad"
                    type="number"
                    min="1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="parte.input_nro"
                    label="Nro. de parte de ingreso"
                    counter
                    maxlength="15"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="1">
                  <v-select
                    v-model="parte.currency"
                    :items="currency_items"
                    :rules="[v => !!v || 'Seleccione una moneda']"
                    label="Moneda"
                    required
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    v-model="parte.ammount_paid"
                    :prefix="parte.currency === 'SOLES' ? 'S/.' : '$'"
                    type="number"
                    min="0"
                    label="Monto pagado"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="parte.bill_nro"
                    label="Nro. de comprobante"
                    counter
                    maxlength="15"
                    autocomplete="nope"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    ref="emission_menu"
                    v-model="emission_menu"
                    :close-on-content-click="false"
                    :return-value.sync="parte.bill_emission"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="parte.bill_emission"
                        label="Fecha de emisión de comprobante"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="parte.bill_emission"
                      no-title
                      scrollable
                      locale="es-pe"
                      @input="$refs.emission_menu.save(parte.bill_emission)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    ref="expire_menu"
                    v-model="expire_menu"
                    :close-on-content-click="false"
                    :return-value.sync="parte.product_expire"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="parte.product_expire"
                        label="Fecha de vencimiento de producto"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="parte.product_expire"
                      no-title
                      scrollable
                      locale="es-pe"
                      @input="$refs.expire_menu.save(parte.product_expire)"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeAddDialog">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="submitParte">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container class="my-6">
      <div>
        <v-row>
          <v-col>
            <div class="text-h5 text-md-h3">Inventario de Materia Prima</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!!this.products">
            <v-data-table
              :headers="headers"
              :items="products"
              item-key="codigo"
              class="elevation-1"
              :search="search"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  class="mx-4"
                ></v-text-field>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon medium class="mr-2" @click="agregarParte(item)">
                  mdi-plus-thick
                </v-icon>
                <v-icon medium @click="verPartes(item)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <ProductReport />
  </div>
</template>
<script>
import ProductReport from '../components/products/ProductReport'
export default {
  components: {
    ProductReport
  },
  data: () => ({
    add_dialog: false,
    emission_menu: false,
    expire_menu: false,
    search: '',
    clase_producto: false,
    products: [],
    crear: false,
    currency_items: ['SOLES', 'DOLARES'],
    product: {},
    parte: {
      currency: 'SOLES'
    },
    selected_item: {},
    productRules: {
      codigo: [
        v => !!v || 'Ingrese el código de producto',
        v => (v && v.length === 7) || 'El código debe tener 7 caracteres'
      ],
      descripcion: [v => !!v || 'Ingresa la descripción de producto']
    },
    valid: true
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Código',
          align: 'start',
          sortable: true,
          value: 'product.code'
        },
        { text: 'Almacén', value: 'warehouse' },
        {
          text: 'Descripción',
          value: 'product.description',
          filtereable: true,
          sortable: false
        },
        { text: 'Clase', value: 'product.class', sortable: false },
        { text: 'Subclase', value: 'subclass' },
        { text: 'Unidad', value: 'unit', sortable: false },
        { text: 'Stock', value: 'stock' },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },
  watch: {
    add_dialog(val) {
      val || this.closeAddDialog()
    }
  },
  mounted: function() {
    this.$makeGetRequest('/api/rawmaterials').then(response => {
      this.products = response.data.data
      console.log(response)
    })
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      )
    },
    async save() {
      this.product.class_id = this.items.indexOf(this.product.class) + 1
      console.log(this.product)
      await this.$makePostRequest('api/rawmaterials/store', this.product)
      this.$makeGetRequest('/api/rawmaterials').then(response => {
        console.log('Update data')
        this.products = response.data.data
        this.$showMessage('Producto insertado', 'success')
      })
    },
    closeAddDialog() {
      this.add_dialog = false
      this.selected_item = {}
      this.parte = {
        currency: 'SOLES'
      }
    },
    agregarParte(item) {
      console.log(item)
      this.add_dialog = true
      this.selected_item = item
      this.parte.raw_material_id = item.id
    },
    async verPartes(item) {
      console.log(item.product)
      await this.$store.dispatch('getProductReport', {
        id: item.id,
        productName: item.product.description
      })
    },
    async submitParte() {
      console.log(this.parte)
      this.curren
      await this.$makePostRequest('api/rawmaterials/input', this.parte)
      this.$makeGetRequest('/api/rawmaterials').then(response => {
        console.log('Update data')
        this.products = response.data.data
        this.$showMessage('Stock Actualizado', 'success')
      })
      this.add_dialog = false
      this.selected_item = null
      this.parte = {}
    }
  }
}
</script>
